// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { LargeIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityDottedShippingLargeIcon = (props: LargeIconProps) => (
  <Icon {...props} viewBox="0 0 64 64">
    <path
      d="M32 18.7292L25.186 28.9502H4.0625L10.8765 18.7292H32ZM32 18.7292V46.6667M32 18.7292H38.5868M32 18.7292L38.8139 28.9502H59.9374L53.1234 18.7292H48.8079M10.8765 28.9502V46.6667H53.1235V28.9502"
      stroke="currentcolor"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityDottedShippingLargeIcon;
