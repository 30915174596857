import React, { useEffect, useState } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import useMartyContext from 'hooks/useMartyContext';
import { onAddToCart } from 'store/ducks/cart/actions';
import { isDesktop } from 'helpers/ClientUtils';
import { cartError, cartIsLoading, changeQuantity, isSuccessfulCartResponse } from 'actions/cart';
import { fetchHeartList } from 'actions/hearts';
import type { AppState } from 'types/app';
import type { CartItem as CartItemType } from 'types/mafia';
import { translateCartError } from 'apis/mafia';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import CartItem from 'components/cart/CartItem';

import css from 'styles/components/cart/cartFavorites.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

export const CartFavorites = (props: PropsFromRedux) => {
  const { cartIsLoading, isCustomer, fetchHeartList, cartError, changeQuantity, onAddToCart, cart, heartsFilteredOOSAndAsin } = props;

  const [adding, setAdding] = useState<void | string>();
  const { marketplace, testId } = useMartyContext();
  const {
    cart: { cartName },
    checkout: { allowMoveToFavorites }
  } = marketplace;

  useEffect(() => {
    if (allowMoveToFavorites && isDesktop() && isCustomer) {
      fetchHeartList({ suppressAuthCatch: true });
    }
  }, [allowMoveToFavorites, fetchHeartList, isCustomer]);

  const onAddToCartClick = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>, win = window) => {
    const { asin, merchantId } = currentTarget.dataset;

    setAdding(asin);
    cartIsLoading();

    changeQuantity({ items: [{ asin, quantity: 1, merchantId }] }).then(response => {
      const error = translateCartError(response);

      if (error) {
        cartError(error);
        win.scrollTo(0, 0);
      } else if (isSuccessfulCartResponse(response)) {
        const product = response.activeItems?.filter((item: CartItemType) => item.asin === asin);
        setAdding(undefined);
        onAddToCart(product[0]);
      }
    });
  };

  const makeCTAVerbiage = ({ isInCart, favAdding }: { isInCart: boolean; favAdding: boolean }) => {
    // already in cart
    if (isInCart) {
      return `Added to ${cartName}`;
    }

    // in the process of adding to cart
    if (favAdding) {
      return 'Adding...';
    }

    // is not in cart
    return `Add to ${cartName}`;
  };

  const makeHearts = (item: any) => {
    // TODO ts use hearts type when its defined
    const { activeItems = [], savedItems = [] } = cart;

    const { asin, itemId, merchantId } = item;

    const allCartItems = activeItems.concat(savedItems);

    const favAdding = asin === adding;
    const isInCart = !!allCartItems.some(item => item.asin === asin);

    return (
      <CartItem key={itemId} item={item} hasFullDetails={false} isFavorite={true}>
        <div className={css.action}>
          <button
            type="button"
            disabled={isInCart || favAdding}
            data-asin={asin}
            data-id={itemId}
            data-merchant-id={merchantId}
            data-test-id={testId('addToCart')}
            onClick={onAddToCartClick}
          >
            {makeCTAVerbiage({ isInCart, favAdding })}
          </button>
        </div>
      </CartItem>
    );
  };

  if (!heartsFilteredOOSAndAsin?.length) {
    return null;
  }

  return (
    <div className={css.container}>
      <h2>Your Favorites</h2>
      <div className={css.heading}>
        <span>Item</span>
      </div>

      <div className={css.items}>{heartsFilteredOOSAndAsin.map(makeHearts)}</div>
    </div>
  );
};

export const mapStateToProps = (state: AppState) => {
  const {
    cart: { cartObj },
    cookies,
    hearts: { heartsFilteredOOSAndAsin }
  } = state;

  const isCustomer = !!cookies['x-main'];

  return {
    cart: cartObj,
    heartsFilteredOOSAndAsin,
    isCustomer
  };
};

const mapDispatchToProps = {
  cartError,
  cartIsLoading,
  changeQuantity,
  fetchHeartList,
  onAddToCart
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedCartFavorites = connector(CartFavorites);
export default withErrorBoundary('CartFavorites', ConnectedCartFavorites);
