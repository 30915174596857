import React from 'react';

import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { CartResponse } from 'types/mafia';

import css from 'styles/components/cart/cartCheckout.scss';

interface Props {
  itemCountVerbiage: string;
  taxVerbiage: string;
  cart: CartResponse;
  onCheckingOut: (...args: any[]) => void;
  loading: boolean;
}

export const CartCheckout = ({ itemCountVerbiage, taxVerbiage, cart, onCheckingOut, loading }: Props) => {
  const { testId, marketplace } = useMartyContext();

  const {
    cart: { cartName, cartCodeVerbiage }
  } = marketplace;

  const {
    activeItemTotalQuantity,
    subtotal: { amount }
  } = cart;

  return (
    <div className={css.container} data-test-id={testId('cartCheckout')}>
      <button type="button" disabled={loading || !activeItemTotalQuantity} onClick={onCheckingOut} data-test-id={testId('proceedToCheckout')}>
        {loading ? 'Updating...' : 'Proceed to Checkout'}
      </button>

      <p>{cartCodeVerbiage}</p>

      <h3>
        {cartName} summary ({itemCountVerbiage})
      </h3>

      <dl className={css.subtotal}>
        <dt data-test-id={testId('subtotalLabel')}>
          Subtotal<span> ({itemCountVerbiage})</span>
        </dt>
        <dd data-test-id={testId('subtotal')}>{toUSD(amount)}</dd>
      </dl>
      <span className={css.legal} dangerouslySetInnerHTML={{ __html: taxVerbiage }} data-test-id={testId('taxVerbiage')} />
    </div>
  );
};

const WithErrorBoundaryCartCheckout = withErrorBoundary('CartCheckout', CartCheckout);
export default WithErrorBoundaryCartCheckout;
