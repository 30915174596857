import React, { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import useMartyContext from 'hooks/useMartyContext';
import { fetchCartRecos } from 'actions/cart';
import { isDesktop } from 'helpers/ClientUtils';
import { onCartRecoClick } from 'store/ducks/cart/actions';
import CardMedia from 'components/common/card/CardMedia';
import getCardData from 'data/getCardData';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { AppState } from 'types/app';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/cart/cartRecos.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

export const CartRecos = (props: PropsFromRedux) => {
  const { testId, marketplace } = useMartyContext();

  const {
    cart: { janus, cartObj: { activeItems = [] } = {} },
    fetchCartRecos,
    onCartRecoClick
  } = props;

  const {
    cart: { showRecos }
  } = marketplace;

  const { recos, title } = janus?.['zap_cart'] || {};

  useEffect(() => {
    // if recos are enabled, is desktop only, has activeItems
    showRecos && isDesktop() && fetchCartRecos({ activeItems });
  }, [activeItems, fetchCartRecos, showRecos]);

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { productId, index } = e.currentTarget.dataset;
    onCartRecoClick(productId, index, 'CUSTOMERS_WHO_BOUGHT_WIDGET');
  };

  if (!Array.isArray(recos)) {
    return null;
  }

  if (!recos?.length) {
    return null;
  }

  return (
    <div className={css.container} data-test-id={testId('recoCarousel')}>
      <Carousel
        header={{ title: String(title) }}
        slides={recos.map((item, index) => {
          const { styleId, productUrl, brandName, productName, productId } = item;
          const { media } = getCardData({ ...item, index });
          return (
            <Link
              to={productUrl}
              onClick={handleOnClick}
              className={css.item}
              key={styleId}
              data-product-id={productId}
              data-index={index}
              data-test-id={testId('cartReco')}
            >
              <CardMedia {...media} />

              <span data-test-id={testId('recoBrandName')}>{brandName}</span>
              <span data-test-id={testId('recoProductName')}>{productName}</span>
            </Link>
          );
        })}
      />
    </div>
  );
};

export const mapStateToProps = (state: AppState) => {
  const { cart } = state;

  return {
    cart
  };
};

const connector = connect(mapStateToProps, { fetchCartRecos, onCartRecoClick });
const ConnectedCartRecos = connector(CartRecos);
export default withErrorBoundary('ConnectedCartRecos', ConnectedCartRecos);
