import React, { useEffect } from 'react';
import type { AnyAction, Store } from 'redux';
import type { Location } from 'history';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import type { ThunkDispatch } from 'redux-thunk';
import { Toast } from '@mweb/zappos-ui/Toast';
import { useLocation } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import type { AppState } from 'types/app';
import type { EmptyObject } from 'types/utility';
import type { CartItem as CartItemType, CartResponse } from 'types/mafia';
import useMartyContext from 'hooks/useMartyContext';
import { groupCartItemsBySizePrediction } from 'helpers/CartUtils';
import { CART_PAGE } from 'constants/amethystPageTypes';
import { cartError, fetchCartItems, fetchCartPromos, navigateToCheckout, syncCartLocalStorage, updateCartCount } from 'actions/cart';
import { onCartContinueShoppingClick, onCartHeadBannerClick } from 'store/ducks/cart/actions';
import { pageTypeChange } from 'actions/common';
import { pluralize } from 'helpers/index';
import { translateCartError } from 'apis/mafia';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import { Loader } from 'components/Loader';
import EmptyCart from 'components/cart/EmptyCart';
import CartItem from 'components/cart/CartItem';
import CartFavorites from 'components/cart/CartFavorites';
import CartCheckout from 'components/cart/CartCheckout';
import GuestCheckoutCartCheckout from 'components/cart/GuestCheckoutCartCheckout';
import CollectionListCarousel from 'components/account/Collections/CollectionListCarousel';
import PaymentIcons from 'components/cart/PaymentIcons/PaymentIcons';
import CartHeadBannerGuestCheckout from 'components/cart/CartHeadBannerGuestCheckout/CartHeadBannerGuestCheckout';
import ContinueShopping from 'components/cart/ContinueShopping';
import CartHeadBanner from 'components/cart/CartHeadBanner';
import CartRecos from 'components/cart/CartRecos';
import CartErrors from 'components/cart/CartErrors';
import { track } from 'apis/amethyst';
import { evRecommendationClick } from 'events/recommendations';
import { firePixelEvent } from 'actions/pixelServer';
import { htmlEscapedTemplate } from 'helpers/htmlEscapedTemplate';
import NewCartItem from 'components/cart/NewCartItem/NewCartItem';
import useKratosCheckoutEnabled from 'hooks/useKratosCheckoutEnabled';

import css from 'styles/containers/cart.scss';

interface OwnProps {
  showFixedQuantity: boolean;
  updateCartCount: (...args: any[]) => any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const SESSION_EXPIRED_PARAM = 'sessionExpired';
const SESSION_EXPIRED_MESSAGE = `We've returned you to your bag for security while you were away, but don't worry, all your items are still here.`;

const isNonEmptyCartObject = (response: CartResponse | EmptyObject): response is CartResponse =>
  (response as CartResponse).activeItems !== undefined;

export const Cart = (props: Props) => {
  const { router, marketplace, testId } = useMartyContext();

  const {
    cart: { showTopBanner, cartName, restoreEmptyCart, legalVerbiage, allowAdjustQuantity, showFitRecommendations },
    checkout: {
      helpUrl: { salesTax }
    }
  } = marketplace;

  const {
    cart,
    fetchCartItems,
    fetchCartPromos,
    isCustomer,
    navigateToCheckout,
    onCartContinueShoppingClick,
    onCartHeadBannerClick,
    pageTypeChange,
    promoData,
    syncCartLocalStorage,
    updateCartCount
  } = props;

  const { isLoaded, cartObj, promos, loading = false } = cart;

  const { activeItems = [], savedItems = [], activeItemTotalQuantity = 0, subtotal: { amount } = { amount: 0 } } = cartObj || {};

  const isKratosCheckout = useKratosCheckoutEnabled();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const sessionExpired = params.get(SESSION_EXPIRED_PARAM) === 'true';

  // on mount
  useEffect(() => {
    pageTypeChange('cart');
    firePixelEvent('cart');
  }, [pageTypeChange]);

  // fetch cart promos
  useEffect(() => {
    if (!!showTopBanner && !promos) {
      fetchCartPromos();
    }
  }, [fetchCartPromos, promos, showTopBanner]);

  // fetch cart info
  useEffect(() => {
    if (!isLoaded) {
      fetchCartItems({ firePixel: true });
    } else if (isNonEmptyCartObject(cartObj)) {
      restoreEmptyCart && syncCartLocalStorage(cartObj);
      updateCartCount(cartObj);
    }
  }, [cartObj, fetchCartItems, isLoaded, restoreEmptyCart, syncCartLocalStorage, updateCartCount]);

  const onCheckingOut = (e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToCheckout(router, CART_PAGE);
  };

  const onCollectionItemClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    { cardData: { productId }, index }: { cardData: { productId: string }; index: number }
  ) => {
    track(() => [
      evRecommendationClick,
      {
        index,
        recommendationType: 'COLLECTION_LIST',
        recommendationValue: productId,
        recommendationSource: 'COLLECTIONS',
        widgetType: 'CART_COLLECTIONS'
      }
    ]);
  };

  if (!isLoaded) {
    return <Loader />;
  }

  const taxVerbiage = htmlEscapedTemplate(legalVerbiage, { salesTax });

  if (!(activeItems.length || savedItems.length)) {
    return (
      <EmptyCart isCustomer={isCustomer}>
        <CollectionListCarousel onCollectionItemClick={onCollectionItemClick} />
        <CartFavorites />
      </EmptyCart>
    );
  }

  const itemCountVerbiage = `${activeItemTotalQuantity} ${pluralize('item', activeItemTotalQuantity)}`;
  const { itemsWithFitRecommended, itemsWithFitNotRecommended, itemsWithNoRecommendationAvailable } = groupCartItemsBySizePrediction(activeItems);
  const numberOfNotRecommended = itemsWithFitNotRecommended.length;

  return (
    <SiteAwareMetadata>
      <div className={isKratosCheckout ? css.gcContainer : css.container}>
        {!isKratosCheckout && (
          <>
            <ContinueShopping onCartContinueShoppingClick={onCartContinueShoppingClick} />
            <CartHeadBanner promoData={promoData} onCartHeadBannerClick={onCartHeadBannerClick} />
          </>
        )}
        <h1>
          <span className={cn({ [css.hideCartCount]: !activeItemTotalQuantity || isKratosCheckout })}>{itemCountVerbiage} in </span>
          My {cartName}
        </h1>

        {sessionExpired && <Toast className="my-4 max-w-fit" msg={SESSION_EXPIRED_MESSAGE} variant="info" isDismissible={true} />}
        <div className="my-3 w-full md:w-[66%]">
          <CartErrors />
        </div>

        <div className={css.wrapper}>
          {isKratosCheckout ? (
            <div className="flex flex-col">
              {showFitRecommendations ? (
                <>
                  {Boolean(numberOfNotRecommended) && (
                    <div className={css.fitNotRecommended}>
                      <p className={css.fitCallout}>
                        {numberOfNotRecommended === 1 ? 'This' : 'These'} {pluralize('size', numberOfNotRecommended)} might not fit you!
                      </p>
                      <p>
                        Our handy-dandy sizing feature suggests that {pluralize('this', numberOfNotRecommended)}{' '}
                        {pluralize('size', numberOfNotRecommended)} might not be the best fit for you.
                      </p>
                      {itemsWithFitNotRecommended.map((item: CartItemType) => (
                        <NewCartItem item={item} key={item.cartItemId} />
                      ))}
                    </div>
                  )}
                  {itemsWithFitRecommended.map((item: CartItemType) => (
                    <NewCartItem item={item} isRecommendedFit={true} key={item.cartItemId} />
                  ))}
                  {itemsWithNoRecommendationAvailable.map((item: CartItemType) => (
                    <NewCartItem item={item} key={item.cartItemId} />
                  ))}
                </>
              ) : (
                activeItems.map(item => {
                  const { cartItemId } = item;
                  return <NewCartItem item={item} key={cartItemId} />;
                })
              )}
              {savedItems.map(item => {
                const { cartItemId } = item;
                return <NewCartItem item={item} isUnavailable={true} key={cartItemId} />;
              })}
            </div>
          ) : (
            <div className={css.items}>
              <div className={css.heading}>
                <span>Item</span>
                <span>Price {allowAdjustQuantity && ' / Quantity'}</span>
              </div>
              {showFitRecommendations ? (
                <>
                  {Boolean(numberOfNotRecommended) && (
                    <div className={css.fitNotRecommended}>
                      <p className={css.fitCallout}>
                        {numberOfNotRecommended === 1 ? 'This' : 'These'} {pluralize('size', numberOfNotRecommended)} might not fit you!
                      </p>
                      <p>
                        Our handy-dandy sizing feature suggests that {pluralize('this', numberOfNotRecommended)}{' '}
                        {pluralize('size', numberOfNotRecommended)} might not be the best fit for you.
                      </p>
                      {itemsWithFitNotRecommended.map((item: CartItemType) => (
                        <CartItem item={item} key={item.cartItemId} />
                      ))}
                    </div>
                  )}
                  {itemsWithFitRecommended.map((item: CartItemType) => (
                    <CartItem item={item} isRecommendedFit={true} key={item.cartItemId} />
                  ))}
                  {itemsWithNoRecommendationAvailable.map((item: CartItemType) => (
                    <CartItem item={item} key={item.cartItemId} />
                  ))}
                </>
              ) : (
                activeItems.map(item => {
                  const { cartItemId } = item;
                  return <CartItem item={item} key={cartItemId} />;
                })
              )}
              {savedItems.map(item => {
                const { cartItemId } = item;
                return <CartItem item={item} isUnavailable={true} key={cartItemId} />;
              })}
            </div>
          )}
          <div className={isKratosCheckout ? css.gcSide : css.side}>
            {isNonEmptyCartObject(cartObj) && (
              <div className={css.checkoutWrapper}>
                {isKratosCheckout ? (
                  <>
                    <div data-test-id={testId('guestCheckoutCartCheckoutWrapper')}>
                      <GuestCheckoutCartCheckout
                        isLoading={loading}
                        amount={amount}
                        itemCountVerbiage={itemCountVerbiage}
                        onCheckingOut={onCheckingOut}
                        activeItemTotalQuantity={activeItemTotalQuantity}
                      />
                    </div>

                    <PaymentIcons />
                    <CartHeadBannerGuestCheckout
                      promoData={promoData}
                      onCartHeadBannerClick={onCartHeadBannerClick}
                      data-test-id="cartHeadBannerGuestCheckout"
                    />
                  </>
                ) : (
                  <div data-test-id="cartCheckoutWrapper">
                    <CartCheckout
                      loading={loading}
                      cart={cartObj}
                      taxVerbiage={taxVerbiage}
                      itemCountVerbiage={itemCountVerbiage}
                      onCheckingOut={onCheckingOut}
                    />
                    <p className={css.legal} data-test-id={testId('legal')} dangerouslySetInnerHTML={{ __html: taxVerbiage }} />
                  </div>
                )}
              </div>
            )}
          </div>
          {!isKratosCheckout && <CollectionListCarousel onCollectionItemClick={onCollectionItemClick} />}
        </div>
        <CartRecos />
        <CartFavorites />
      </div>
    </SiteAwareMetadata>
  );
};

Cart.fetchDataOnServer = (store: Store, location: Location) => {
  const { dispatch } = store as {
    dispatch: ThunkDispatch<AppState, void, AnyAction>;
  };
  const { err } = parse(location?.search);

  if (err) {
    const msg = translateCartError({ statusCode: '400', id: err });
    dispatch(cartError(msg));
  }

  return Promise.resolve(dispatch(fetchCartItems({ firePixel: true })));
};

Cart.defaultProps = {
  updateCartCount
};

const mapStateToProps = (state: AppState) => {
  const { cart, cookies } = state;
  const isCustomer = !!cookies['x-main'];
  const promoData = cart.promos?.slotData?.['sidebar-2'];

  return {
    cart,
    isCustomer,
    promoData
  };
};

const mapDispatchToProps = {
  cartError,
  syncCartLocalStorage,
  fetchCartItems,
  fetchCartPromos,
  navigateToCheckout,
  onCartContinueShoppingClick,
  onCartHeadBannerClick,
  pageTypeChange
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ConnectedCart = connector(Cart);
export default ConnectedCart;
