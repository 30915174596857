import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { cartError, cartIsLoading, changeQuantity } from 'actions/cart';
import MelodyCardProduct from 'components/common/melodyCard/MelodyCardProduct';
import { fetchHeartList } from 'actions/hearts';
import useMartyContext from 'hooks/useMartyContext';
import MarketplaceAwareLink from 'components/common/MarketplaceAwareLink';
import { ProductQuickViewReco } from 'components/productdetail/ProductQuickView';
import { formatHeartsForMelodyCard } from 'helpers/HeartUtils';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/account/collectionListCarousel.scss';
// eslint-disable-next-line css-modules/no-unused-class
import recosCSS from 'styles/components/productdetail/recos.scss';

export const MarketplaceLink = ({ cardData }) => {
  const { merchantId, productUrl } = cardData;
  return (
    <div className={css.linkContainer}>
      <MarketplaceAwareLink className={css.viewItemLink} merchantId={merchantId} to={productUrl ?? ''}>
        View Item
      </MarketplaceAwareLink>
    </div>
  );
};

export const CollectionListCarousel = props => {
  const {
    hearts: { heartsFilteredOOS } = {},
    fetchHeartList,
    isCustomer,
    onCollectionItemClick,
    cart: { activeItems, savedItems }
  } = props;
  const { testId, marketplace } = useMartyContext();
  const {
    checkout: { allowMoveToFavorites },
    cart: { cartName }
  } = marketplace;
  const allCartItems = [...activeItems, ...savedItems];

  // fetch hearts if allowMoveToFavorites and isCustomer
  useEffect(() => {
    if (allowMoveToFavorites && isCustomer) {
      fetchHeartList({ suppressAuthCatch: true });
    }
  }, [fetchHeartList, isCustomer, allowMoveToFavorites]);

  if (!heartsFilteredOOS || !(heartsFilteredOOS.length > 0)) {
    return null;
  }

  const recoElements = heartsFilteredOOS.map(favorite => {
    // this step in theory could be moved to a reducer, the problem is
    // the calculation on price which breaks cart item and possible other prices
    // that need a raw number and not converted USD which melodyCardProduct expects
    const formattedHeart = formatHeartsForMelodyCard(favorite);
    const { styleId } = formattedHeart;
    const isInCart = !!allCartItems.some(item => item.styleId === favorite.styleId);
    const belowImageOptions = {
      addToCartText: isInCart ? `In ${cartName}` : 'Add Item',
      hideCartModalPopUp: true
    };

    return (
      <MelodyCardProduct
        key={`c-${styleId}`}
        msaImageParams={null}
        cardData={formattedHeart}
        componentStyle={null}
        shouldLazyLoad={true}
        vertical={false}
        melodyCardTestId={testId('collectionCarouselCard')}
        onComponentClick={onCollectionItemClick}
        belowImageRenderer={ProductQuickViewReco}
        belowImageOptions={belowImageOptions}
      />
    );
  });

  return (
    <div className={css.container} data-test-id={testId('collectionCarousel')}>
      {recoElements.length > 5 ? (
        <Carousel header={{ title: 'From Your Favorites' }} slides={recoElements} />
      ) : (
        <>
          <h2 className={css.title}>From Your Favorites</h2>
          <div className={cn(recosCSS.recos, recosCSS.noCarousel)}>{recoElements}</div>
        </>
      )}
    </div>
  );
};

export const mapStateToProps = state => {
  const {
    cart: { cartObj: cart },
    cookies,
    hearts
  } = state;

  const isCustomer = !!cookies['x-main'];

  return {
    cart,
    hearts,
    isCustomer
  };
};

const ConnectedCollectionListCarousel = connect(mapStateToProps, {
  cartError,
  cartIsLoading,
  changeQuantity,
  fetchHeartList
})(CollectionListCarousel);

export default ConnectedCollectionListCarousel;
