import React from 'react';
import { Link } from 'react-router-dom';

import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/cart/cartHeadBanner.scss';

interface Props {
  onCartHeadBannerClick: (...args: any[]) => void;
  promoData: {
    cta: string;
    heading: string;
    src: string;
    body: string;
    ctalink: string;
  };
}

export const CartHeadBanner = ({ onCartHeadBannerClick, promoData }: Props) => {
  const { marketplace, testId } = useMartyContext();
  const {
    cart: { showTopBanner, returnWindow }
  } = marketplace;

  if (!showTopBanner) {
    return null;
  }

  const { cta, heading, src, body, ctalink } = promoData || {};

  return (
    <div className={css.container} data-test-id={testId('CartHeadBanner')}>
      <div className={css.freeShip}>
        <h4 className="text-base">Free Shipping and Returns</h4>
        <p>{returnWindow} days to return items for a refund!</p>
        <Link
          onClick={() => onCartHeadBannerClick('shipping_policy')}
          to="/c/general-questions#shipping-and-return"
          data-test-id={testId('shippingLink')}
        >
          Shipping and Return Policy
        </Link>
      </div>
      <div className={css.privacy}>
        <h4 className="text-base">Privacy Policy</h4>
        <p>We don’t rent or sell your personal information to anyone.</p>
        <Link onClick={() => onCartHeadBannerClick('privacy_policy')} to="/c/privacy-policy" data-test-id={testId('privacyLink')}>
          Read our Privacy Policy
        </Link>
      </div>

      {
        /* if promo data comes back (in symphony) */
        body && (
          <div style={{ backgroundImage: `url('${src}')` }}>
            <h4>{heading}</h4>
            <p>{body}</p>
            <Link onClick={() => onCartHeadBannerClick('symphony_promo')} to={ctalink} data-test-id={testId('promoLearnMore')}>
              {cta}
            </Link>
          </div>
        )
      }
    </div>
  );
};

const WithErrorBoundaryCartHeadBanner = withErrorBoundary('CartHeadBanner', CartHeadBanner);
export default WithErrorBoundaryCartHeadBanner;
