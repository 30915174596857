import React from 'react';
import { connect } from 'react-redux';

import UtilityDottedShippingLargeIcon from 'tailwind/components/Icons/UtilityDottedShippingLargeIcon';
import useMartyContext from 'hooks/useMartyContext';
import Button from 'components/common/Button';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { type SharedRewardState } from 'types/rewards';
import { toUSD } from 'helpers/NumberFormats';

interface PromoData {
  cta: string;
  heading: string;
  src: string;
  body: string;
  ctalink: string;
}

interface CartHeadBannerGuestCheckoutProps {
  onCartHeadBannerClick: (type: string) => void;
  promoData: PromoData;
  rewards: SharedRewardState;
}

export const CartHeadBannerGuestCheckout = ({ onCartHeadBannerClick, promoData, rewards }: CartHeadBannerGuestCheckoutProps) => {
  const { marketplace, testId } = useMartyContext();
  const {
    cart: { returnWindow }
  } = marketplace;

  const { cta, heading, src, body, ctalink } = promoData || {};

  const dollarValue = rewards?.transparencyPointsForCart?.totalPoints?.dollarValue || 0;

  const isEnrolled = rewards?.isEnrolled;

  return (
    <div className="container flex flex-col" data-test-id={testId('shippingVipInfoContainer')}>
      <div className="flex gap-2 pb-7 pt-0">
        <div>
          <UtilityDottedShippingLargeIcon size={48} data-test-id={testId('icon-shipping-large')} />
        </div>
        <div>
          <h4 className="text-base font-semibold text-primary" data-test-id={testId('shipping-heading')}>
            Free Shipping and Returns
          </h4>
          <p className="mb-1 text-sm text-primary" data-test-id={testId('shipping-body')}>
            {returnWindow} days to return items for a refund!
          </p>
          <Button
            variant="text"
            onClick={() => onCartHeadBannerClick('shipping_policy')}
            href="/c/general-questions#shipping-and-return"
            className="font-semibold"
            data-test-id={testId('shipping-policy-link')}
          >
            Shipping and Return Policy
          </Button>
        </div>
      </div>
      {!isEnrolled && (
        <div className="flex gap-2 border-t border-subtle pb-4 pt-5">
          <div>
            <UtilityDottedShippingLargeIcon size={48} data-test-id={testId('icon-vip-large')} />
          </div>
          <div>
            <h4 className="text-base font-semibold text-primary" data-test-id={testId('vip-heading')}>
              Become a Zappos VIP
            </h4>
            <p className="mb-1 text-sm text-primary" data-test-id={testId('vip-body')}>
              Earn FREE Expedited Shipping and {toUSD(dollarValue)} in VIP points on this purchase.
            </p>
            <Button
              variant="text"
              onClick={() => onCartHeadBannerClick('privacy_policy')}
              href="c/vip-dash"
              className="font-semibold"
              data-test-id={testId('vip-policy-link')}
            >
              Join Zappos VIP
            </Button>
          </div>
        </div>
      )}

      {
        /* if promo data comes back (in symphony) */
        body && (
          <div style={{ backgroundImage: `url('${src}')` }} data-test-id={testId('promo-container')}>
            <h4 data-test-id={testId('promo-heading')}>{heading}</h4>
            <p data-test-id={testId('promo-body')}>{body}</p>
            <Button onClick={() => onCartHeadBannerClick('symphony_promo')} height="small" href={ctalink} data-test-id={testId('promoLearnMore')}>
              {cta}
            </Button>
          </div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state: { sharedRewards: SharedRewardState }) => {
  const { sharedRewards: rewards } = state;
  return { rewards };
};

const WithErrorBoundaryCartHeadBanner = withErrorBoundary('CartHeadBannerGuestCheckout', connect(mapStateToProps)(CartHeadBannerGuestCheckout));
export default WithErrorBoundaryCartHeadBanner;
