export const NUMBER_SANS_COMMAS_RE_GEN = /\B(?=(\d{3})+(?!\d))/g;

const regexFormatter = (number: number) => (Number.isNaN(number) ? '' : `$${number.toFixed(2).toString().replace(NUMBER_SANS_COMMAS_RE_GEN, ',')}`);

export interface NumberFormat {
  format: (number: number) => string;
  fallback: boolean;
}

export const getFormatter = (): Intl.NumberFormat | NumberFormat => {
  if (typeof Intl !== 'undefined') {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  }

  // Safari 9.x, IE 9/10 don't support Intl
  return { format: regexFormatter, fallback: true };
};
const formatter = getFormatter();

const toUSD = (number: string | number | void, formatZeroValues = true): string => {
  if (number === '' || number === null || number === undefined || (!formatZeroValues && number === 0)) {
    return '';
  }
  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number;
  return Number.isNaN(parsedNumber) ? '' : formatter.format(parsedNumber);
};

export default toUSD;
