import { PaymentIcon } from '@mweb/zappos-ui/PaymentIcon';

import useMartyContext from 'hooks/useMartyContext';

export const PaymentIcons = () => {
  const { testId } = useMartyContext();
  return (
    <div className="container mx-auto mb-8 mt-5 flex justify-center sm:mb-6 sm:mt-4" data-test-id={testId('guestCheckoutPaymentIcons')}>
      <div className="justify-left flex w-fit flex-wrap gap-2">
        <PaymentIcon method="visa" data-test-id={testId('PaymentIcon-visa')} />
        <PaymentIcon method="mastercard" data-test-id={testId('PaymentIcon-mastercard')} />
        <PaymentIcon method="discover" data-test-id={testId('PaymentIcon-discover')} />
        <PaymentIcon method="amex" data-test-id={testId('PaymentIcon-amex')} />
        <PaymentIcon method="amazon" data-test-id={testId('PaymentIcon-amazon')} />
        <PaymentIcon method="paypal" data-test-id={testId('PaymentIcon-paypal')} />
        <PaymentIcon method="afterpay" data-test-id={testId('PaymentIcon-afterpay')} />
      </div>
    </div>
  );
};

export default PaymentIcons;
