import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';
import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import Button from 'components/common/Button';

interface Props {
  itemCountVerbiage: string;
  onCheckingOut: (e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => void;
  activeItemTotalQuantity: number;
  amount: number;
  isLoading: boolean;
}

export const GuestCheckoutCartCheckout = ({ itemCountVerbiage, amount, activeItemTotalQuantity, onCheckingOut, isLoading }: Props) => {
  const { testId } = useMartyContext();

  return (
    <div className="flex flex-col gap-3.5 rounded-md bg-primary-minimal px-4 py-6">
      <h3 className="mb-0.5 text-xl font-medium capitalize text-primary">Order summary</h3>

      <dl className="flex justify-between gap-3">
        <dt className="text-base font-normal text-primary" data-test-id={testId('subtotalItems')}>
          Subtotal<span> ({itemCountVerbiage})</span>
        </dt>
        <dd className="text-base font-medium text-primary" data-test-id={testId('subtotalPrice')}>
          {toUSD(amount)}
        </dd>
      </dl>
      <dl className="flex justify-between gap-3">
        <dt className="text-base font-normal text-primary" data-test-id={testId('shippingLabel')}>
          Shipping
        </dt>
        <dd className="text-base font-semibold text-primary">FREE</dd>
      </dl>
      <dl className="flex justify-between gap-3">
        <dt className="flex flex-wrap gap-x-1 text-base font-normal text-primary" data-test-id={testId('estimatedTax')}>
          Estimated{' '}
          <span className="flex items-center gap-1">
            Tax
            <UtilityStrokeInfoOutlineSmallIcon size={16} />
          </span>
        </dt>
        <dd className="text-right text-base font-medium text-primary">Calculated at Checkout</dd>
      </dl>
      <div className="border-b border-dashed"></div>
      <div className="pb-2.5 pt-2">
        <dl className="flex justify-between gap-3">
          <dt className="text-xl font-semibold text-primary" data-test-id={testId('estimatedTotal')}>
            Estimated Total
          </dt>
          <dd className="text-xl font-semibold text-primary">{toUSD(amount)}</dd>
        </dl>
      </div>

      <Button type="button" disabled={isLoading || !activeItemTotalQuantity} onClick={onCheckingOut} data-test-id={testId('proceedToCheckout')}>
        {isLoading ? 'Updating...' : 'Checkout'}
      </Button>
    </div>
  );
};

const WithErrorBoundaryGuestCheckoutCartCheckout = withErrorBoundary('GuestCheckoutCartCheckout', GuestCheckoutCartCheckout);
export default WithErrorBoundaryGuestCheckoutCartCheckout;
