// eslint-disable-next-line no-restricted-imports
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { ProductCardHorizontal, ProductCardHorizontalDetails, ProductCardHorizontalImage } from '@mweb/zappos-ui/ProductCardHorizontal';
import { ProductCardPrice } from '@mweb/zappos-ui/ProductCard';
import { QuantitySelector } from '@mweb/zappos-ui/QuantitySelector';

import Button from 'components/common/Button';
import useMartyContext from 'hooks/useMartyContext';
import UtilityDottedHeartOutlineMediumIcon from 'tailwind/components/Icons/UtilityDottedHeartOutlineMediumIcon';
import { cartError, cartIsLoading, changeQuantity } from 'actions/cart';
import { onModifyQuantity, onRemoveFromCart } from 'store/ducks/cart/actions';
import { fetchHeartList, heartProduct, unHeartProduct } from 'actions/hearts';
import { HEART_DEFAULT_LIST_ID } from 'constants/apis';
import toUSD from 'utils/toUSD';
import FinalSale from 'components/common/FinalSale';
import { translateCartError } from 'apis/mafia';
import { SmallLoader } from 'components/Loader';
import type { ItemProps } from 'types/mafia';

interface Props {
  item: ItemProps;
  isRecommendedFit?: boolean;
  isUnavailable?: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
export type NewCartItemProps = Props & PropsFromRedux;

interface LowStockWarningProps {
  isUnavailable?: boolean;
  availableAmount: number;
  testId: (id: string) => string;
}

interface LoaderOverlayProps {
  asin: string;
  updatingAsin: string;
}

const LowStockWarning = ({ isUnavailable, availableAmount, testId }: LowStockWarningProps) => (
  <>
    {!isUnavailable && availableAmount > 0 && availableAmount < 4 && (
      <span data-test-id={testId('lowStock')} className="absolute rounded bg-alert px-1 py-0.5 pt-0 text-alert">
        {availableAmount} left
      </span>
    )}
  </>
);

const LoaderOverlay = ({ asin, updatingAsin }: LoaderOverlayProps) => (
  <>
    {updatingAsin === asin && (
      <div className="absolute bottom-0 left-0 right-0 top-0 z-10 bg-default-minimal opacity-[0.85]">
        <SmallLoader />
      </div>
    )}
  </>
);

function NewCartItem({
  item,
  isUnavailable,
  isRecommendedFit,
  onRemoveFromCart,
  changeQuantity,
  heartProduct,
  fetchHeartList,
  cartIsLoading,
  cartError,
  onModifyQuantity
}: NewCartItemProps) {
  const {
    testId,
    marketplace: {
      shortName,
      pdp: { egcUrl }
    }
  } = useMartyContext();

  const {
    asin,
    brandName,
    catalogBundle: {
      image: { alt: imageAlt, href, url },
      style
    },
    color,
    deliveryDate,
    egc,
    finalSale,
    glProductGroupType,
    merchantId,
    originalPrice,
    price,
    productId,
    quantity,
    recipientEmail,
    sizing: {
      languageTagged: { displaySize, shoeWidth }
    }
  } = item;

  const isItemGiftCard = egc && glProductGroupType === 'gl_gift_card';
  const stock = quantity || 1;
  const availableAmount = item.stock || quantity;

  const [updatingAsin, setUpdatingAsin] = useState('');
  const [baseCount, setBaseCount] = useState(quantity);

  useEffect(() => {
    setBaseCount(quantity);
  }, [quantity, item]);

  let link: string = '';
  let alt: string = '';

  if (egc) {
    link = egcUrl;
    alt = `${shortName} Gift Card`;
  } else {
    link = asin ? href : `/product/${productId}`;
    alt = imageAlt;
  }

  const onItemUpdate = (asin: string) => {
    cartIsLoading();
    setUpdatingAsin(asin);
  };

  const onItemUpdateDone = () => {
    setUpdatingAsin('');
  };

  const onQuantityChange = debounce((data: { value: number; isIncrement: boolean }) => {
    setBaseCount(data.value);
    if (data.value === 0) {
      onRemoveItem(asin);
      return;
    }
    onModifyQuantity(data.value, asin);
    onItemUpdate(asin);
    changeQuantity({ items: [{ asin, quantity: data.value }] }).then(response => {
      onItemUpdateDone();

      const error = translateCartError(response);
      if (error) {
        cartError(error);
      }
    });
  }, 250);

  const onRemoveItem = (asin: string) => {
    setUpdatingAsin(asin);
    const itemData = { asin, quantity: 0 };
    changeQuantity({ items: [itemData] });
    onRemoveFromCart(asin, true);
  };

  const onHeartItem = (asin: string) => {
    setUpdatingAsin(asin);
    heartProduct({ subItemId: asin, listId: HEART_DEFAULT_LIST_ID, merchantId }, fetchHeartList);
    onRemoveItem(asin); // After hearting the product, removes the item from cart
  };

  const TailIcon = UtilityDottedHeartOutlineMediumIcon;

  const testIdName = egc ? 'giftCardItem' : 'cartItem';

  return (
    <div
      data-test-id={testId(testIdName)}
      className="relative mx-2 flex flex-col justify-between gap-y-4 border-b border-button-subtle py-6 last:border-b-0 md:mx-0 md:mr-8 md:flex-row md:gap-y-0"
    >
      <LoaderOverlay asin={asin} updatingAsin={updatingAsin} />

      <ProductCardHorizontal className="w-full gap-x-6">
        <ProductCardHorizontalImage
          data-test-id={testId('cartItemImage')}
          href={link}
          alt={alt}
          src={url}
          imageClassName="aspect-[4/5] md:w-48 md:h-44 md:p-2"
        >
          <LowStockWarning testId={testId} isUnavailable={isUnavailable} availableAmount={availableAmount} />
        </ProductCardHorizontalImage>
        <ProductCardHorizontalDetails
          brandName={brandName}
          productName={style}
          color={!isItemGiftCard ? color : undefined}
          size={!isItemGiftCard ? displaySize : undefined}
          isRecommendedFit={isRecommendedFit}
          width={!isItemGiftCard ? shoeWidth : undefined}
          className="w-full"
          recipientEmail={isItemGiftCard ? recipientEmail : undefined}
          deliveryDate={isItemGiftCard ? deliveryDate : undefined}
        >
          <div className="hidden items-center gap-x-6 md:flex">
            <Button
              variant="text"
              aria-label="Remove Item"
              data-test-id={testId('removeButton')}
              type="button"
              className="font-semibold"
              onClick={() => onRemoveItem(asin)}
            >
              Remove
            </Button>
            <Button
              variant="text"
              data-test-id={testId('moveToFavoritesButton')}
              className="font-semibold"
              onClick={() => onHeartItem(asin)}
              tailIcon={<TailIcon className="stroke-2" size={24} />}
            >
              Move To
            </Button>
          </div>

          {isUnavailable ? (
            <p data-test-id={testId('unavailable')} className="text-xs font-bold uppercase text-alert md:hidden">
              Unavailable
            </p>
          ) : (
            <ProductCardPrice data-test-id={testId('price')} className="flex-col md:hidden" price={stock * price} msrp={stock * originalPrice}>
              {stock !== 1 && <span className="text-xs font-normal text-tertiary">({toUSD(price)} each)</span>}
            </ProductCardPrice>
          )}
          {finalSale && <FinalSale className="flex md:hidden" />}

          {!isItemGiftCard && (
            <QuantitySelector
              size="small"
              variant={isUnavailable ? 'disabled' : 'base'}
              className="md:hidden"
              baseCount={isUnavailable ? 0 : baseCount}
              data-test-id={testId('quantitySelectorMobile')}
              maxCount={availableAmount <= 10 ? availableAmount : 10}
              data-asin={asin}
              onQuantityChange={data => onQuantityChange(data)}
            />
          )}
        </ProductCardHorizontalDetails>
      </ProductCardHorizontal>
      <div className="hidden flex-col items-end gap-y-4 md:flex">
        {isUnavailable ? (
          <p data-test-id={testId('unavailable')} className="text-xs font-bold uppercase text-alert">
            Unavailable
          </p>
        ) : (
          <div className="flex flex-col">
            <ProductCardPrice data-test-id={testId('price')} className="flex-col items-end" price={stock * price} msrp={stock * originalPrice}>
              {stock !== 1 && <span className="text-xs font-normal text-tertiary">({toUSD(price)} each)</span>}
            </ProductCardPrice>
            {finalSale && <FinalSale />}
          </div>
        )}
        {!isItemGiftCard && (
          <QuantitySelector
            variant={isUnavailable ? 'disabled' : 'base'}
            size="small"
            baseCount={isUnavailable ? 0 : baseCount}
            data-test-id={testId('quantitySelector')}
            maxCount={availableAmount <= 10 ? availableAmount : 10}
            data-asin={asin}
            onQuantityChange={data => onQuantityChange(data)}
          />
        )}
      </div>

      <div className="flex items-center justify-between gap-x-6 md:hidden">
        <Button
          variant="text"
          data-test-id={testId('removeButtonMobile')}
          aria-label="Remove Item"
          type="button"
          className="font-semibold"
          onClick={() => onRemoveItem(asin)}
        >
          Remove
        </Button>
        <Button
          variant="text"
          data-test-id={testId('moveToFavoritesButtonMobile')}
          className="font-semibold"
          onClick={() => onHeartItem(asin)}
          tailIcon={<TailIcon className="stroke-2" size={24} />}
        >
          Move To
        </Button>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  cartError,
  cartIsLoading,
  changeQuantity,
  onModifyQuantity,
  onRemoveFromCart,
  heartProduct,
  unHeartProduct,
  fetchHeartList
};

const connector = connect(null, mapDispatchToProps);

export default connector(NewCartItem);
